import ActionsMenu from '../../../components/Table/ActionsMenu';

export function getColumns( 
    viewReport?: ((row: undefined) => void) | null,
    toggleIsEdit?: ((row: undefined) => void) | null,
    deleteHotelChain?: ((row: undefined) => void) | null,
) {
    return [
        {
            name: 'Total de pontos',
            selector: (row: { TransactionPoints: number }) => (
                <span>{row.TransactionPoints}</span>
            ),
        },
        {
            name: 'Unidade',
            selector: (row: { RoomId: number }) => (
                <span>{row.RoomId}</span> 
            ),
        },
        {
            name: 'Título',
            selector: (row: { Title: string }) => (
                <span>{row.Title}</span>
            ),
        },
        {
            name: 'Detailing',
            selector: (row: { Detailing: string }) => (
                <span>{row.Detailing}</span>
            ),
        },
        {
            name: 'Entrada ou saída?',
            selector: (row: { PointType: number }) => (
                <span>{row.PointType === 1 ? 'Entrada' : 'Saída'}</span>
            ),
        },
        // {
        //     name: 'Tipo de inclusão',
        //     selector: (row: { Tipo: string }) => (
        //         <span>{row.Tipo}</span>
        //     ),
        // },
        {
            name: 'Data da inclusão',
            selector: (row: { CreatedAt: string }) => {
                const date = new Date(row.CreatedAt);
                const day = String(date.getDate()).padStart(2, '0');  
                const month = String(date.getMonth() + 1).padStart(2, '0');  
                const year = date.getFullYear();
        
                return <span>{`${day}/${month}/${year}`}</span>;
            },
        },
      
        {
            name: '',
            width: '60px',
            cell: (row: undefined) => (
                <ActionsMenu
                    onView={viewReport ? () => viewReport(row) : null}
                    onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
                />
            ),
        },
    ];
}
