import ky from "ky";
import { IUserData, IUserNetworkData } from "./types";
const { REACT_APP_API_URL } = process.env;

export interface IUserManagement {
  Id: number;
  Name: string;
  Email: string;
  IsActive: boolean;
  modules: string[];
}

const API_URL = REACT_APP_API_URL;

class UserApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async listUserData(): Promise<IUserData | undefined> {
    const headers = await this.getHeaders("application/json");
    const userID = localStorage.getItem("userId");
    try {
      const response = await ky.get(
        `${API_URL}user/${userID}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar dados do usuário", error);
    }
  }

  public async createUser(userData: any): Promise<void> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.post(`${API_URL}user`, {
        json: userData,
        headers
      });
  
      if (response.status !== 204 && response.headers.get('content-length') !== '0') {
        return response.json();
      }

    } catch (error) {
      console.error("Erro ao cadastrar usuário:", error);
      throw error;
    }
  }
  

  public async updateUser(userId: number, userData: any): Promise<void> {
    const headers = await this.getHeaders("application/json");
  
    try {
      
      const response = await ky.put(`${API_URL}user/${userId}/admin`, {
        json: userData, 
        headers
      });
  
      if (response.status !== 204 && response.headers.get('content-length') !== '0') {
        const responseBody = await response.json();
      }
  
    } catch (error) {
      if (error instanceof Response) {
        const errorBody = await error.json();
        console.error("Detalhes do erro retornado pela API:", errorBody);
      }
      console.error("Erro ao atualizar usuário:", error);
      throw error;
    }
  }
  



public async toggleUserStatus(userId: number, isActive: boolean): Promise<void> {
  const headers = await this.getHeaders("application/json");
  try {
      await ky.patch(`${API_URL}user/${userId}/is-active?IsActive=${isActive}`, {
          headers
      });
    
  } catch (error) {
      console.error("Erro ao atualizar status do usuário:", error);
      throw error;
  }
}


  public async listUserNetworks(
    userId: number
  ): Promise<IUserNetworkData[] | undefined> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}user/user-networks/${userId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao listar dados do usuário", error);
    }
  }

  public async listUserManagement(networkUnitId: number): Promise<IUserManagement[]> {
    const headers = await this.getHeaders();
    try {
      const response = await ky.get(
        `${API_URL}user/user-management/${networkUnitId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao buscar gestão de usuários", error);
      throw error;
    }
  }

}

export default new UserApi();
