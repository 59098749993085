import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface Hotel {
  Id: number;
  Name: string;
}

interface SelectEnterpriseProps {
  onSelectedHotelChange: (selectedHotelId: number | null) => void; 
}

export default function SelectEnterprise({ onSelectedHotelChange }: SelectEnterpriseProps) {
  const [hotels, setHotels] = useState<{ label: string; id: number }[]>([]);
  const [selectedHotel, setSelectedHotel] = useState<{ label: string; id: number } | null>(null);

  useEffect(() => {
    const formatLabel = (label: string): string => {
      return label
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) 
        .join(" ");
    };

    const fetchHotels = async () => {
      const storedNetworks = localStorage.getItem("networks");
      if (storedNetworks) {
        const parsedNetworks: Hotel[] = JSON.parse(storedNetworks);
  
        const formattedHotels = parsedNetworks.map((hotel) => ({
          label: formatLabel(hotel.Name),
          id: hotel.Id,
        }));
  
        setHotels(formattedHotels);

        const savedNetworkId = localStorage.getItem("selectedNetwork");
        if (savedNetworkId) {
          const savedHotel = formattedHotels.find(h => h.id === Number(savedNetworkId));
          setSelectedHotel(savedHotel ?? (formattedHotels[0] || null));
        } else if (formattedHotels.length > 0) {
          setSelectedHotel(formattedHotels[0]);
        }
      }
    };
  
    fetchHotels();
  }, []);
  

  useEffect(() => {
    if (selectedHotel) {
      localStorage.setItem("selectedNetwork", selectedHotel.id.toString());
      onSelectedHotelChange(selectedHotel.id); 
    }
  }, [selectedHotel, onSelectedHotelChange]);  

  const handleHotelChange = (event: any, newValue: { label: string; id: number } | null) => {
    if (!newValue) return;
  
    
    setSelectedHotel(newValue);
    
    setTimeout(() => {
  
      window.location.reload();
    }, 100); 
  };

  return (
    <div className="w-[352px] h-[56px] border-l-4 border-[#076BEF] font-main sm:w-[100%]">
      <span className="text-sm top-3 px-1 text-[#076BEF]">Você está vendo</span>
      <Autocomplete
        options={hotels}
        value={selectedHotel}
        onChange={handleHotelChange}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
          />
        )}
      />
    </div>
  );
}