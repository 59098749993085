import React from 'react';
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ContinerSwitch } from "../Channels/Channels/styles";

import ActionsMenu from '../../components/Table/ActionsMenu';

export function getColumns(
  toggleStatus?: (row: any) => void,     
  handleEditUser?: (row: any) => void,  
) {
  
  const handleToggleChange = (row: any) => {
    if (toggleStatus) {
      toggleStatus(row);
    }
  };

  return [
    {
      name: "Nome",
      selector: (row: { name: string }) => row.name,
    },
    {
      name: "E-mail",
      selector: (row: { email: string }) => (
        <span style={{ color: "#6D7882" }}>{row.email}</span>
      ),
    },
    {
      name: "Módulos",
      selector: (row: { modules: string[] }) =>
        row.modules.length > 0 ? row.modules.join(", ") : "N/A",
    },
    {
      name: "Status",
      // Mantém o switch que você já usa
      selector: (row: { active: boolean }) => (
        <ContinerSwitch>
          <FormGroup>
            <FormControlLabel
            control={
              <Switch
                checked={row.active}
                onChange={() => toggleStatus && toggleStatus(row)}  
                edge="start"
              />
            }
            label={row.active ? "Ativo" : "Inativo"}
          />
          </FormGroup>
        </ContinerSwitch>
      ),
    },
    {
      name: "Ações",
      width: "60px",
      cell: (row: any) => (
        <ActionsMenu
          onEdit={handleEditUser ? () => handleEditUser(row) : null}
        />
      ),
    },
  ];
}
