// Points.tsx
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import MultiSelect from "../../../components/MultiSelect";
import SelectEnterprise from "../../../components/SelectEnterprise";
import { Grid2 } from "@mui/material";
import { useEffect, useState } from "react";
import PageSize from "../../../components/Table/PageSize";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { getColumns } from "./columns";
import DeleteModal from "../../../components/DeleteModal";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import pointsApi from "../../../api/points/pointsApi";
import { Spinner } from "../../../components/Spinner";

enum inputType {
  ENTRY = "ENTRY",
  OUTPUT = "OUTPUT",
}

type Unit = {
  Number: string;
  RoomId: number;
  UserId: number;
};

type FilterForm = {
  unity: Unit[]; 
  type: { value: string; label: string }[];
};

export default function Points() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingContract, setDeletingContract] = useState<null>(null);
  const [listPoints, setListPoints] = useState([]);
  const [bigNumber, setBigNumber] = useState([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [selectActive, setSelectActive] = useState();
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);

  const [filters, setFilters] = useState<{
    unity: number[];      
    type: string | null;  
  }>({ unity: [], type: null });

  const data = [
    { value: 'entry', label: 'Entrada' },
    { value: 'output', label: 'Saída' },
    { value: 'all', label: 'Todos' }
  ];

  const handleSelectedHotelChange = async (selectedHotelId: number | null) => {
    if (selectedHotelId !== selectActive) {
      setIsLoadingTable(true);
      setSelectActive(selectedHotelId);
      localStorage.setItem("selectedNetwork", selectedHotelId?.toString() || '');
    }
  };

  const fetchData = async (networkUnitId: number | null, appliedFilters = filters) => {
    if (!networkUnitId) return;
    setIsLoadingTable(true);
    try {
      
      const [unitsResponse, pointsResponse, bigNumberResponse] = await Promise.all([
        pointsApi.getUnity(),
        pointsApi.getNetworkUnitPoints(networkUnitId, currentPage, pageSize, appliedFilters),
        pointsApi.getBigNumberPoints()
      ]);

      setUnits(unitsResponse);
      setListPoints(pointsResponse.items || pointsResponse);
      setBigNumber(bigNumberResponse[0]?.count || 0);

    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsLoadingTable(false);
    }
  };

  useEffect(() => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (networkUnitId) {
      setSelectActive(parseInt(networkUnitId));
    }
  }, []);

  useEffect(() => {
    if (!selectActive) return;
    fetchData(selectActive);
  }, [selectActive, currentPage, pageSize]);

  const newChannelCreateSchema = z.object({
    type: z.any(),
    unity: z.any(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const { handleSubmit, control, formState: { errors }, reset } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      unity: [],
      type: []
    }
  });

  function handleFiltersSubmit(data: FilterForm) {
    const unityIds = data.unity.map((unit) => unit.RoomId);
  
    let selectedType = null;
    const chosenType = data.type?.[0]?.value;
    if (chosenType === 'entry') selectedType = '1';
    else if (chosenType === 'output') selectedType = '2';
    else if (chosenType === 'all') selectedType = null;
  
    const newFilters = {
      unity: unityIds,
      type: selectedType
    };
  
    setFilters(newFilters);
  
    if (selectActive) {
      fetchData(selectActive, newFilters);
    }
  }

  function handleClearFilters() {
    reset({ unity: [], type: [] });
    const clearedFilters = { unity: [], type: null };
    setFilters(clearedFilters);
    if (selectActive) {
      fetchData(selectActive, clearedFilters);
    }
  }

  const viewReport = (row) => {
  };

  const editChannel = (row) => {
  };

  const deleteItem = (row) => {
    setDeletingContract(row);
  };

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full sm:flex-col">
        <div className="w-1/2">
          <Header
            title="Pontos"
            subTitle="Realize a inclusão dos pontos para os proprietários"
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end  gap-5 w-1/2 sm:w-[100%]">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Nova inclusão de pontos"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/programa-de-fidelidade/incluir-pontos")}
            />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(handleFiltersSubmit)} className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6 sm:flex-col">
          <div className="w-2/4 sm:w-[100%]">
            <Controller
              name="unity"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  label="Unidade"
                  data={units}
                  onChange={(selectedValues: Unit[]) => field.onChange(selectedValues)}
                  style={{ width: "100%" }}
                  selectedValues={field.value || []}
                  errors={errors.unity}
                  message="Campo obrigatório"
                  file={false}
                  keyField="RoomId"
                  labelField="Number"
                />
              )}
            />
          </div>
          <div className="w-2/4 sm:w-[100%]">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  label="Tipo de inclusão"
                  data={data}
                  onChange={(values) => field.onChange(values)}
                  style={{ width: "100%" }}
                  errors={errors.type}
                  message="Campo obrigatório"
                  file={false}
                  keyField="value"
                  labelField="label"
                  selectedValues={field.value || []}
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={handleClearFilters}
            type="button"
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            type="submit"
          />
        </div>
      </form>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882]">
            <p>
              <span className="font-bold">{bigNumber}</span> itens
              cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        {isLoadingTable ? (
          <Spinner />
        ) : listPoints.length === 0 ? (
          <p className="text-center my-4 text-gray-600">Nenhum dado encontrado</p>
        ) : (
          <Table data={listPoints} columns={getColumns(viewReport, null, deleteItem)} hidePagination />
        )}
      </div>

      <Pagination
        pageCount={numberOfPages}
        currentPage={currentPage - 1}
        setPage={(page) => {
          setCurrentPage(page + 1);
        }}
        setPagePrevious={(page) => setCurrentPage(page + 1)}
      />

      {deletingContract && (
        <DeleteModal
          itemName={deletingContract.name}
          confirm={() => deleteItem(deletingContract)}
          cancel={() => setDeletingContract(null)}
        />
      )}
    </Grid2>
  );
}
