// pointsApi.ts
import ky from "ky";
import { FilterPointsDTO } from "./dto/filter-point.dto";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class PointsAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async getUnity(): Promise<any[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}room/file/${networkUnitId}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar dados da unidade:", error);
      throw error;
    }
  }

  public async getBigNumberPoints(): Promise<any[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network networkUnitId não encontrado no localStorage.");
    }

    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}points/extract-points/big-number/${networkUnitId}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();

      return data;
    } catch (error) {
      console.error("Erro ao buscar pontos:", error);
      throw error;
    }
  }


  public async getNetworkUnitPoints(
    networkUnitId: number, 
    page: number, 
    pageSize: number,
    filters?: { unity: number[]; type: string | null }
  ): Promise<any[]> {
    if (!networkUnitId) {
      throw new Error("Network Unit ID é obrigatório.");
    }

    const dataBody: FilterPointsDTO = {
      networkUnitId: networkUnitId,
      page: page,
      pageSize: pageSize
    };

    if (filters?.type !== undefined && filters?.type !== null) {
      dataBody.pointType = parseInt(filters.type);
    } 

    if (filters?.unity !== undefined && filters?.unity !== null) {
      dataBody.roomId = filters.unity[0];
    } 
    try {
      const headers = await this.getHeaders("application/json");
      const response = await ky.post(`${API_URL}points/filter-admin`, {
        json: dataBody,
        headers,
      });
      const data: any = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar os pontos da unidade de rede:", error);
      throw error;
    }
  }

  public async getOwners(): Promise<any[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}user/investor/network-unit/${networkUnitId}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar dados de proprietários:", error);
      throw error;
    }
  }

    public async postNewPoint(
      transactionPoints: number,
      pointType: number,
      roomId: number,
      userId: number,
      title: string,
      detailing: string,
      networkUnitId: number
  ): Promise<unknown> {
      const dataBody = {
          transactionPoints,
          pointType,
          roomId,
          userId,
          title,
          detailing,
          networkUnitId
      };

      try {
          const headers = await this.getHeaders();
          const response = await ky.post(`${API_URL}points`, {
              json: dataBody,
              headers,
          });
          return response;  
      } catch (error) {
          console.error("Erro ao cadastrar nova pontuação", error);
          throw error;
      }
  }


  public async getPoints(): Promise<any[]> {
    const user = localStorage.getItem("userId");

    if (!user) {
      throw new Error("Network user não encontrado no localStorage.");
    }

    try {
      const headers = await this.getHeaders();
      const response = await ky.get(
        `${API_URL}points/extract-points/${user}`,
        {
          headers,
        }
      );

      const data: any[] = await response.json();

      return data;
    } catch (error) {
      console.error("Erro ao buscar pontos:", error);
      throw error;
    }
  }

  public async upadtePoint(
    transactionPoints: number,
    pointType: number,
    roomId: number,
    userId: number,
    title: string,
    detailing: string,
  ): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const dataBody = {
      transactionPoints: transactionPoints,
      pointType: pointType,
      roomId: roomId,
      userId: userId,
      title: title,
      detailing: detailing,
    };
    try {
      const headers = await this.getHeaders();
      await ky.put(`${API_URL}points`, {
        json: dataBody,
        headers,
      });
      return;
    } catch (error) {
      console.error("Erro ao atualizar pontuação", error);
      throw error;
    }
  }
}

export default new PointsAPI();
