import React, { useState, useEffect } from 'react';
import { Grid2 } from '@mui/material';
import Header from "../../components/Header";
import Button from "../../components/Button";
import SelectEnterprise from "../../components/SelectEnterprise";
import Input from "../../components/Input";
import Table from "../../components/Table";
import PageSize from '../../components/Table/PageSize';
import Pagination from '../../components/Table/Pagination';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import UserApi from "../../api/user/user";
import { getColumns } from './columns'; 

export default function UserManagement() {
  const [selectActive, setSelectActive] = useState<number | null>(null);
  const [nomeFiltro, setNomeFiltro] = useState<string>("");
  const [emailFiltro, setEmailFiltro] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();

  const toggleStatus = async (row: any) => {
    try {
        await UserApi.toggleUserStatus(row.id, !row.active);  
        setUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.id === row.id ? { ...user, active: !user.active } : user
            )
        );
      
    } catch (error) {
        console.error("Erro ao atualizar o status:", error);
    }
};


  const fetchUsers = async (networkUnitId: number) => {
    setLoading(true);
    try {
      const data = await UserApi.listUserManagement(networkUnitId);
      
      const formattedData = data.map((user: any) => ({
        id: user.Id,           
        name: user.Name,
        email: user.Email,
        modules: Array.isArray(user.modules)
          ? user.modules.map((module: string) => {
              try {
                const parsedModule = JSON.parse(module);
                return parsedModule.name || "Desconhecido";
              } catch (error) {
                console.warn("Erro ao parsear módulo:", module, error);
                return "Desconhecido";
              }
            })
          : [],
        active: user.IsActive,
      }));


      const filteredUsers = formattedData.filter(
        (user: any) =>
          user.name.toLowerCase().includes(nomeFiltro.toLowerCase()) &&
          user.email.toLowerCase().includes(emailFiltro.toLowerCase())
      );

      const offset = (currentPage - 1) * pageSize;
      setUsers(filteredUsers.slice(offset, offset + pageSize));
      setTotalItems(filteredUsers.length);
    } catch (error) {
      console.error("Erro ao carregar dados", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = (rowData: any) => {
    navigate("/novo-usuario", {
      state: {
        isEdit: true,
        data: rowData,
      },
    });
  };

  useEffect(() => {
    if (selectActive) fetchUsers(selectActive);
  }, [selectActive, currentPage, pageSize, nomeFiltro, emailFiltro]);

  const handleClearFilters = () => {
    setNomeFiltro("");
    setEmailFiltro("");
    setCurrentPage(1);
  };

  const handleSearch = () => {
    if (selectActive) fetchUsers(selectActive);
  };

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId);
  };

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Gestão de Usuários"
            subTitle="Visualize e gerencie os usuários cadastrados."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>
          <div>
            <Button
              title="+ Novo usuário"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/novo-usuario")}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6">
          <div className="w-2/4">
            <Input
              label="Nome"
              placeholder="Digite o nome do usuário"
              type="text"
              value={nomeFiltro}
              onChange={(e) => setNomeFiltro(e.target.value)}
            />
          </div>
          <div className="w-2/4">
            <Input
              label="E-mail"
              placeholder="Digite o e-mail do usuário"
              type="text"
              value={emailFiltro}
              onChange={(e) => setEmailFiltro(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={handleClearFilters}
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={handleSearch}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882] font-main">
            <p>
              <span className="font-bold font-main">{totalItems}</span> usuários cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size: number) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        {loading ? (
          <div className="text-center font-bold text-lg py-5">
            <Spinner />
          </div>
        ) : (
          <Table
            data={users}
            columns={getColumns(toggleStatus, handleEditUser)} 
          />
        )}

        <Pagination
          pageCount={Math.ceil(totalItems / pageSize)}
          currentPage={currentPage}
          setPage={setCurrentPage}
        />
      </div>
    </Grid2>
  );
}
