/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Grid2 } from "@mui/material";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import SelectEnterprise from "../../../components/SelectEnterprise";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import { getColumns } from "./columns";
import { useEffect, useState } from "react";
import PageSize from "../../../components/Table/PageSize";
import Pagination from "../../../components/Table/Pagination";
import MultiSelect from "../../../components/MultiSelect";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal";
import channel from "../../../api/channel/channel";
import { ResponseNumberOfChannels } from "./types";
import { Spinner } from "../../../components/Spinner";

export default function Channels() {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState<number>(10);
  const [numberOfChannel, setNumberOfChannel] = useState<ResponseNumberOfChannels>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [deletingContract, setDeletingContract] = useState(null);
  const [dataChannel, setDataChannel] = useState([]);
  const [nomeCanal, setNomeCanal] = useState("");
  const [selectActive, setSelectActive] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const dataStatus = [
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
  ];

  const handleMultiSelectChange = (values) => {
    setSelectedValues(values);
  };

  const handleInputChange = (event) => {
    setNomeCanal(event.target.value);
  };

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId || undefined);
  };

  const getActiveStatusFromFilters = () => {
    if (!selectedValues || selectedValues.length === 0) {
      return undefined;
    }

    const hasAtivos = selectedValues.some((v) => v.value === "ativos");
    const hasInativos = selectedValues.some((v) => v.value === "inativos");

    if (hasAtivos && !hasInativos) return true;
    if (hasInativos && !hasAtivos) return false;

    return undefined;
  };

  async function loadChannels() {
    if (!selectActive) return;
  
    setLoading(true);
    try {
      const titleFilter = nomeCanal.trim() !== "" ? nomeCanal.trim() : undefined;
      const activeStatus = getActiveStatusFromFilters();

      const response = await channel.filterAdminChannels(
        pageSize,
        currentPage,
        titleFilter,
        activeStatus
      );

      const formattedData = response?.map((x: any) => ({
        Id: x.Id,
        Title: x.Title,
        CreatedAt: x.CreatedAt,
        Active: x.Active,
        ChannelIcon: x.ChannelIcon,
        NetworkUnitId: x.NetworkUnitId
      })) || [];
      
      setDataChannel(formattedData);
    } catch (error) {
      console.error("Erro ao filtrar canais", error);
    } finally {
      setLoading(false);
    }
  }

  async function listOfNumberChannel() {
    try {
      const response = await channel.listNumberOfChannels();
      if (response) {
        setNumberOfChannel(response);
      } else {
        console.error("Nenhum dado foi retornado de listNumberOfChannels");
      }
    } catch (error) {
      console.error("Erro inesperado ao listar número de canais", error);
    }
  }

  async function deleteItem(deletingItem) {
    const { Id } = deletingItem;

    if (!Id) {
      console.error("Id não definido");
      return;
    }

    try {
      const response = await channel.deleteChannel(Id);
      if (response) {
        await loadChannels();
        await listOfNumberChannel();
        return response;
      } else {
        console.error("Falha ao deletar canal.");
      }
    } catch (error) {
      console.error("Erro ao deletar canal", error);
    } finally {
      setDeletingContract(null);
    }
  }

  const editChannelFn = (row) => {
    navigate("/editar-canal", { state: { isEdit: true, data: row } });
  };

  const handleToggleStatus = async (row) => {
    try {
      const newStatus = !row.Active;
      await channel.editChannel(row.Id, null, row.Title, undefined, newStatus);
      
      setDataChannel((prevData) =>
        prevData.map((item) =>
          item.Id === row.Id ? { ...item, Active: newStatus } : item
        )
      );
  
    } catch (error) {
      console.error("Erro ao atualizar o status do canal:", error);
    }
  };

  const getFilteredChannels = () => {
    
    if (!selectedValues || selectedValues.length === 0) {
      return dataChannel; 
    }
  
    const hasAtivos = selectedValues.some((v) => v.value === "ativos");
    const hasInativos = selectedValues.some((v) => v.value === "inativos");
  
    if (hasAtivos && hasInativos) {
      return dataChannel; 
    }

    return dataChannel.filter((item) => {
      if (hasAtivos && item.Active) return true;
      if (hasInativos && !item.Active) return true;
      return false;
    });
  };
  
  const handleSearch = async () => {
    await loadChannels();
    await listOfNumberChannel();
  };

  const handleClear = async () => {
    setNomeCanal("");
    setSelectedValues([]);
    await loadChannels();
    await listOfNumberChannel();
  };

  useEffect(() => {
    const savedNetwork = localStorage.getItem("selectedNetwork");
    if (savedNetwork) {
      const networkId = Number(savedNetwork);
      setSelectActive(networkId);
    }
  }, []);

  useEffect(() => {
    if (selectActive !== undefined) {
      loadChannels();
      listOfNumberChannel();
    }
  }, [selectActive, pageSize, currentPage]);

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Canais"
            subTitle="Visualize, crie e gerencie os canais de atendimento disponibilizados aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          <Button
            title=" + Novo Canal"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={() => navigate("/novo-canal")}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6">
          <div className="w-2/4">
            <Input
              label="Nome"
              placeholder="Digite o nome do canal"
              type="text"
              value={nomeCanal}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-2/4">
            <MultiSelect
              label="Status"
              data={dataStatus}
              onChange={handleMultiSelectChange}
              style={{ width: "100%" }}
              file={false}
              keyField="value"
              labelField="label"
              values={selectedValues}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={handleClear}
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={handleSearch}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882]">
            <p>
              <span className="font-bold">
                {numberOfChannel?.registrationitem || 0}
              </span>{" "}
              itens cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Table
              data={getFilteredChannels()}
              columns={getColumns(editChannelFn, setDeletingContract, handleToggleStatus)}
              hidePagination
            />
            <Pagination
              pageCount={numberOfPages}
              currentPage={currentPage - 1}
              setPage={(page) => setCurrentPage(page + 1)}
              setPagePrevious={(page) => setCurrentPage(page + 1)}
            />
          </>
        )}
      </div>

      {deletingContract && (
        <DeleteModal
          itemName={deletingContract.Title}
          confirm={() => deleteItem(deletingContract)}
          cancel={() => setDeletingContract(null)}
        />
      )}
    </Grid2>
  );
}
