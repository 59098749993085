/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from "ky";
import { INumberOfChannels, ListChatsTopic, Users } from "./types";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class ChannelAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async listAdminChannels(networkUnitId: number): Promise<any> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.get(
        `${API_URL}chat-topic/admin-chanel/${networkUnitId}`,
        { headers }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async filterAdminChannels(
    pageSize: number,
    offset: number,
    title?: string,
    active?: boolean
  ): Promise<any> {

    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork");
    
    if (!selectedNetwork) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
  
    const networkUnitId = Number(selectedNetwork);
  
    const payload: {
      networkUnitId: number;
      pageSize: number;
      offSet: number;
      title?: string;
      active?: boolean;
    } = {
      networkUnitId,
      pageSize,
      offSet: offset
    };
    

    // Só adiciona title se for fornecido e não vazio
    if (title && title.trim() !== "") {
      payload.title = title.trim();
    }

    // Só adiciona active se for fornecido
    if (typeof active !== "undefined") {
      payload.active = active;
    }

  
    try {
      const response = await ky.post(
        `${API_URL}chat-topic/filter-admin`,
        {
          headers,
          json: payload,
        }
      );
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }  


  public async postNewChannel(
    channelIcon: File | null,
    name: string,
    userIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    formData.append("Title", name);
    formData.append("NetworkUnitId", networkUnitId);
    formData.append("Active", "true");
    userIds.forEach((id) => formData.append("UserIds[]", id));

    if (File && channelIcon) {
      formData.append("ChannelIcon", channelIcon);
    }

    try {

      const headers = await this.getHeaders();
      const response = await ky.post(
        `${API_URL}chat-topic`,
        {
          headers,
          body: formData,
        }
      );
      const data = await response.json();
      
      return data;
    } catch (error) {
      
      throw error;
    }
  }


  public async listNumberOfChannels(): Promise<INumberOfChannels | undefined> {
    const headers = await this.getHeaders("application/json");
    const networkUnitId = localStorage.getItem("selectedNetwork");
    try {
      const response = await ky.get(
        `${API_URL}chat-topic-user/${networkUnitId}`,
        { headers }
      );
      return response.json();
    } catch (error) {
      
      return undefined;
    }
  }

  public async listChannels(): Promise<ListChatsTopic[] | undefined> {
    const headers = await this.getHeaders("application/json");
    const userId = localStorage.getItem("userId");
    const networkUnitId = localStorage.getItem("selectedNetwork");
  
    if (!networkUnitId) {
      
      throw new Error("Network Unit ID não encontrado.");
    }  
  
    try {
      const response = await ky.get(
        `${API_URL}chat-topic/list-channel/${userId}/${networkUnitId}`,
        { headers }
      );
      const data = (await response.json()) as ListChatsTopic[]; 
      
      return data;
    } catch (error) {
      
      return undefined;
    }
  }
  

  public async getResponsibleUsers(): Promise<Users | undefined> {
    const headers = await this.getHeaders("application/json");
    const networkUnitId = localStorage.getItem("selectedNetwork");
    
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
    
    
    try {
      const response = await ky.get(
        `${API_URL}user/admin/units/${networkUnitId}`,
        { headers }
      );
      const data = await response.json() as Users;
      
      return data;
    } catch (error) {
      console.error("Error fetching responsible users:", error);
      return undefined;
    }
  }

  public async deleteChannel(deletingItem: number): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `${API_URL}chat-topic-user/${deletingItem}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      
    }
  }

  public async listChatTopics(networkUnitId: number): Promise<any[]> {
    const headers = await this.getHeaders("application/json");
  
    try {
      const response = await ky.get(
        `${API_URL}chat-topic/network-unit/${networkUnitId}`,
        { headers }
      );
  
      return response.json();
    } catch (error) {
      console.error("Erro ao buscar chat topics:", error);
      throw error;
    }
  }  

  public async editChannel(
    id: string,
    Icon: File | null,
    name: string,
    userIds?: string[],
    active?: boolean
  ): Promise<unknown> {
    const formData = new FormData();

    if (name) {
      formData.append("Title", name.toString());
    }
    if (userIds) {
      userIds.forEach((id) => formData.append("UserIds[]", id));
    }
    if (File && Icon) {
      formData.append("Icon", Icon);
    }
    if (typeof active !== "undefined") {
      formData.append("Active", active ? "true" : "false");
    }
    try {
      const headers = await this.getHeaders();
      const data = await ky.put(
        `${API_URL}chat-topic/${id}`,
        { body: formData, headers }
      );
      return data;
    } catch (error) {
      
      throw error;
    }
  }
}

export default new ChannelAPI();
