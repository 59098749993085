import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SideBarMenu from "../MenuLateral";
import IconMessage from "../../assets/icons/message-icon.svg";
import IconCommunity from "../../assets/icons/fone-icon.svg";
import IconFile from "../../assets/icons/icon-file.svg";
import IconPoints from "../../assets/icons/icon-benefits.svg";
import UserTie from "../../assets/icons/user-tie.svg";
import User from "../../assets/icons/icon-user.svg";
import ky from "ky";
import { AccessModule, Menu, SubTitle } from "../MenuLateral/types";

const { REACT_APP_API_URL } = process.env;

const initialMenus: Menu[] = [
  {
    id: 1,
    title: "Atendimento",
    subTitles: [
      { id: 101, text: "Mensagens", url: "/Mensagens" },
      { id: 102, text: "Canais", url: "/Canais" },
    ],
    imageUrl: IconMessage,
  },
  {
    id: 2, 
    title: "Proprietários",
    url: "/proprietarios",
    imageUrl: UserTie,
  },
  {
    id: 3,
    title: "Comunicados",
    url: "/comunicados",
    imageUrl: IconCommunity,
  },
  {
    id: 4,
    title: "Documentos",
    subTitles: [
      { id: 401, text: "Lista de documentos", url: "/arquivos" },
      { id: 402, text: "Categorias", url: "/arquivos/categorias" },
    ],
    imageUrl: IconFile,
  },
  {
    id: 5,
    title: 'Programa de fidelidade',
    subTitles: [
      { id: 501, text: 'Pontos', url: '/Pontos' },
      { id: 502, text: 'Benefícios', url: '/programa-de-fidelidade' }
    ],
    imageUrl: IconPoints 
  },
  {
    id: 6,
    title: "Gestão de usuários",
    url: "/gestao-usuarios",
    imageUrl: User,
  },
];

const Layout: React.FC = () => {
  const [menus, setMenus] = useState<Menu[]>([]); 
  const [networkUnitId, setNetworkUnitId] = useState<string | null>(localStorage.getItem("selectedNetwork"));

  useEffect(() => {

    fetchMenus();
  }, [networkUnitId]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "selectedNetwork") {
        setNetworkUnitId(event.newValue); 
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  async function fetchMenus() {
    const userId = localStorage.getItem("userId");
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (userId && networkUnitId) {
      await fetchAccessModules(Number(userId), Number(networkUnitId));
    }
  }

  async function getHeaders(contentType?: string): Promise<Record<string, string>> {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  async function fetchAccessModules(userId: number, networkUnitId: number) {
    try {
      const url = `${REACT_APP_API_URL}user/user-permissions/${networkUnitId}/${userId}`;
      const headers = await getHeaders();
      const response: AccessModule[] = await ky
        .get(url, { headers })
        .json<AccessModule[]>();
      const filteredModules = filterMenus(response);
      setMenus(filteredModules);
    } catch (error) {
      console.error("❌ Erro ao buscar os módulos de acesso", error);
    }
  }

  function filterMenus(accessModules: AccessModule[]): Menu[] {
    const activeModuleIds = accessModules
      .filter(module => module.Active)
      .map(module => module.AccessModuleId);

    const updatedMenus = initialMenus
      .map(menu => {
        const isMainMenuActive = activeModuleIds.includes(menu.id);
        
        const updatedSubTitles = menu.subTitles?.filter(subTitle => 
          activeModuleIds.includes(subTitle.id)
        );

        if (isMainMenuActive || (updatedSubTitles && updatedSubTitles.length > 0)) {
          return { 
            ...menu, 
            subTitles: updatedSubTitles 
          };
        }

        return null; 
      })
      .filter(menu => menu !== null) as Menu[];

    return updatedMenus;
  }

  return (
    <div className="flex h-screen">
      <SideBarMenu menus={menus} />
      <div className="flex-grow overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
