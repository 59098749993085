/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";

import Header from "../../../components/Header";
import Input from "../../../components/Input";
import ImageUpload from "../../../components/ImageUpload";
import TextEditor from "../../../components/TextEditor";
import Button from "../../../components/Button";
import MultiSelect from "../../../components/MultiSelect";
import FileApi from "../../../api/file/file";
import CustomToast from "../../../components/Toast";

import StatmenteAPI from "../../../api/statement/statement";

// Tipos e validações
const newChannelCreateSchema = z.object({
  title: z.string().min(1, "Título é obrigatório"),
  dataPublishing: z.string().min(1, "Data é obrigatório"),
  responsibleUsers: z.any(), // só se precisar
  responsibleTypeUsers: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .nonempty("Campo de responsáveis é obrigatório"),
});

type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

export default function NewStatement() {
  const navigate = useNavigate();

  // Estados
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [users, setUsers] = useState<ResponseUsers[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
  });

  // Opções de quem receberá o comunicado
  const listUsersResponsible = [
    { value: "todos", label: "Todos os Proprietários" },
    { value: "dentroPool", label: "Proprietários dentro do pool" },
    { value: "foraPool", label: "Proprietários fora do pool" },
  ];

  // Exemplo caso precise manipular os valores do MultiSelect
  const handleMultiSelectChange = (values: any) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedValues(uniqueValues);
  };

  // Carregar lista de usuários (se necessário para algo futuro)
  async function listUser() {
    try {
      const response = await FileApi.listUsers();
      if (response) {
        const formattedUsers = response.map((user) => ({
          value: user.Id.toString(),
          label: user.Name,
        }));
        setUsers(formattedUsers);
      }
    } catch (error) {
      console.error("Erro ao listar usuários", error);
    }
  }

  useEffect(() => {
    listUser();
  }, []);

  async function handleSubmitForm(data: NewChannelsSchema) {
    setIsLoading(true);

    try {
        let type = "";
        if (data.responsibleTypeUsers[0].value === "todos") type = "1";
        else if (data.responsibleTypeUsers[0].value === "dentroPool") type = "2";
        else if (data.responsibleTypeUsers[0].value === "foraPool") type = "3";

        const formData = new FormData();
        const scheduleISO = dayjs(data.dataPublishing, "YYYY-MM-DD", true).isValid()
        ? dayjs(data.dataPublishing).toISOString()
        : (() => { throw new Error("Data inválida!"); })();
        const networkUnitId = localStorage.getItem("selectedNetwork") || "1";

        formData.append("Schedule", scheduleISO);
        formData.append("Title", data.title);
        formData.append("Text", editorContent);
        formData.append("Type", type);
        formData.append("NetworkUnitId", networkUnitId);

        if (selectedImage) {
            formData.append("File", selectedImage);
        }

        await StatmenteAPI.createNewStatement(formData);
        navigate("/comunicados");

    } catch (error) {
        console.error("Erro ao enviar comunicado:", error);
    } finally {
        setIsLoading(false);
    }
  }



  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      {isLoading && <div className="loading-message">Carregando...</div>}

      <div>
        <div className="flex w-full">
          <div className="w-screen">
            <Header title="Cadastrar Comunicado" arrowIcon={true} />
          </div>
        </div>

        <form id="my-form" onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
            <div className="flex flex-col gap-4 rounded-lg">
              <Controller
                name="responsibleTypeUsers"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    label="Disponibilizar para"
                    data={listUsersResponsible}
                    onChange={(values: any) => {
                      field.onChange(values);
                      handleMultiSelectChange(values);
                    }}
                    style={{ width: "100%" }}
                    register={field}
                    errors={errors.responsibleTypeUsers}
                    message="Campo obrigatório"
                    file={false}
                    keyField="value"
                    labelField="label"
                  />
                )}
              />

              <div className="flex gap-6">
                <div className="w-1/2">
                  <Input
                    label="Título"
                    placeholder="Digite o título do comunicado"
                    type="text"
                    register={register("title")}
                    errors={errors.title}
                  />
                </div>

                <div className="w-1/2">
                  <Input
                    label="Publicação"
                    placeholder="Informe a data de publicação"
                    type="date"
                    register={register("dataPublishing")}
                    errors={errors.dataPublishing}
                  />
                </div>
              </div>
            </div>

            <div>
              <ImageUpload
                title="Foto da capa"
                onImageSelect={setSelectedImage}
                initialImage={null} // Não há edição, então não precisamos setar
              />
            </div>

            <div>
              <TextEditor
                label=""
                value={editorContent}
                onChange={setEditorContent}
              />
            </div>
          </div>
        </form>
      </div>

      <div className="p-10">
        <div className="flex justify-between">
          <Button
            title="Voltar"
            onClick={() => navigate(-1)}
            backGroundColor="#fff"
            color="#F28E00"
            borderRadius={8}
          />
          <Button
            title={isLoading ? "Enviando..." : "Cadastrar comunicado"}
            backGroundColor="#F28E00"
            color="#FAFAFC"
            borderRadius={8}
            form="my-form"
            type="submit"
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
