import { Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout/layout';
import Messages from '../pages/Channels/Messages';
import Channels from '../pages/Channels/Channels';
import NewChannel from '../pages/Channels/Channels/NewChannel';
import NewStatement from '../pages/Statement/NewStatement';
import Statement from '../pages/Statement';
import FilesPage from '../pages/Files';
import NewFile from '../pages/Files/NewFile';
import Benefits from '../pages/Benefits';
import NewBenefit from '../pages/Benefits/NewBenefit';
import ViewReport from '../pages/Benefits/ViewReport';
import Points from '../pages/Benefits/Points';
import NewPoints from '../pages/Benefits/Points/NewPoints';
import Login from '../components/Login';
import PrivateRoute from './privateRoute';
import CategoriesPage from '../pages/Files/NewCategory';
import React from 'react';
import Owners from '../pages/Owners';
import UserManagement from '../pages/UserManagement';
import NewUser from '../pages/UserManagement/NewUser';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/" />
          <Route path="/Mensagens" element={<Messages />} />
          <Route path="/Canais" element={<Channels />} />
          <Route path="/novo-canal" element={<NewChannel />} />
          <Route path="/editar-canal" element={<NewChannel />} />
          <Route path="/cadastrar-comunicado" element={<NewStatement />} />
          <Route path="/comunicados" element={<Statement />} />
          <Route path="/editar-comunicado" element={<NewStatement />} />
          <Route path="/arquivos" element={<FilesPage />} />
          <Route path="/arquivos/cadastrar-arquivo" element={<NewFile />} />
          <Route path="/arquivos/editar-arquivo" element={<NewFile />} />
          <Route path="/arquivos/categorias" element={<CategoriesPage />} />
          <Route path="/programa-de-fidelidade" element={<Benefits />} />
          <Route path="/programa-de-fidelidade/cadastrar-beneficio" element={<NewBenefit />} />
          <Route path="/programa-de-fidelidade/editar-beneficio" element={<NewBenefit />} />
          <Route path="/programa-de-fidelidade/visualizar-relatorios" element={<ViewReport />} />
          <Route path="/pontos" element={<Points />} />
          <Route path="/programa-de-fidelidade/incluir-pontos" element={<NewPoints />} />
          <Route path="/proprietarios" element={<Owners />} />
          <Route path="/gestao-usuarios" element={<UserManagement />} />
          <Route path="/novo-usuario" element={<NewUser />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
