export function getColumns() {
  
  function formatUHNames(uh: string[]): string {
    if (!uh || uh.length === 0) return "N/A";
    const parsedUHNames = uh.map(u => {
      const parsed = JSON.parse(u);
      return parsed.UHName ? parsed.UHName.trim() : "";
    }).filter(name => name);
    return parsedUHNames.length > 0 ? parsedUHNames.join(", ") : "N/A";
  }

  function formatDocumentType(type: number): string {
    // -1 = CNPJ, -2 = CPF
    if (type === -1) return "CNPJ";
    if (type === -2) return "CPF";
    return "N/A";
  }

  return [
    {
      name: "Nome",
      selector: (row: { Name: string; Email: string }) => (
        <div>
          <p>{row.Name}</p>
          <p style={{ color: "#6D7882" }}>{row.Email}</p>
        </div>
      ),
    },
    {
      name: "Tipo de Documento",
      selector: (row: { DocumentType: number }) => formatDocumentType(row.DocumentType),
    },
    {
      name: "Nº do Documento",
      selector: (row: { Document: string }) => row.Document,
    },
    {
      name: "Unidade Habitacional (UH)",
      selector: (row: { uh: string[] }) => formatUHNames(row.uh),
    },
  ];
}
