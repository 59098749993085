/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Input from "../../components/Input";
import MultiSelect from "../../components/MultiSelect";
import SelectEnterprise from "../../components/SelectEnterprise";
import { Grid2 } from "@mui/material";
import { useEffect, useState } from "react";
import PageSize from "../../components/Table/PageSize";
import Table from "../../components/Table";
import DeleteModal from "../../components/DeleteModal";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import statement from "../../api/statement/statement";
import { Spinner } from "../../components/Spinner";
import ActionsMenu from "../../components/Table/ActionsMenu";

const dataType = [
  { value: "all", label: "Todos os proprietários" },
  { value: "owners_inside_pool", label: "Proprietários dentro do pool" },
  { value: "owners_outside_pool", label: "Proprietários fora do pool" },
];

export default function Statement() {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingContract, setDeletingContract] = useState<null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dataMain, setDataMain] = useState([]);
  const [statementData, setStatementData] = useState([]);
  const [listSize, setListSize] = useState(0);
  const [selectActive, setSelectActive] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const defaultFormData = {
    title: "",
    type: [],
    startDate: "",
    endDate: "",
  };

  const [initialData, setInitialData] = useState(defaultFormData);

  const handleMultiSelectChange = (values) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedValues(uniqueValues);
  };

  const editChannel = (row) => {
    
    const filterData = dataMain.filter(value => value.id === row.id);
    navigate("/editar-comunicado", { state: { isEdit: true, data: filterData } });
  };

  async function deleteItem(deletingItem) {
    await statement.deleteStatement(String(deletingItem.id));
    listComunicados(defaultFormData);
    setDeletingContract(null);
  }

  const handleToggleStatus = async (row) => {
    try {
      const newStatus = !row.active;
      await statement.editStatement(
        String(row.id),
        row.scheduling,
        row.title,
        null,
        []
      );
      setDataMain((prev) =>
        prev.map((item) => (item.id === row.id ? { ...item, active: newStatus } : item))
      );
      setStatementData((prev) =>
        prev.map((item) => (item.id === row.id ? { ...item, active: newStatus } : item))
      );
    } catch (error) {
      console.error("Erro ao atualizar o status:", error);
    }
  };

  const newChannelCreateSchema = z.object({
    title: z.string().optional(),
    status: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    ).optional(),
    type: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    ).optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: initialData,
  });

  const handleReset = () => {
    listComunicados(defaultFormData);
    reset(initialData);
  };

  async function listComunicados(data = {}) {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (!networkUnitId) return;

    setLoading(true);
    try {
      const payload = {
        networkUnitId: Number(networkUnitId),
        pageSize: pageSize,
        offSet: currentPage,
      };

      const response = await statement.listStatement(payload);
      const formattedData =
        response?.map((x) => ({
          id: x.id,
          title: x.title,
          scheduling: x.scheduling,
          createdAt: x.createdAt,
          notificationType:
            x.notificationType === 1
              ? "Todos os Proprietários"
              : x.notificationType === 2
              ? "Proprietários dentro do pool"
              : "Proprietários fora do pool",
        })) || [];

      setDataMain(formattedData);
      setStatementData(formattedData);
      setListSize(formattedData.length);
    } catch (error) {
      console.error("Erro ao listar comunicados", error);
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = (data) => listComunicados(data);

  useEffect(() => {
    const storedNetwork = localStorage.getItem("selectedNetwork");
    if (storedNetwork) {
      setSelectActive(Number(storedNetwork));
    }
  }, []);

  useEffect(() => {
    if (!selectActive) return;
    listComunicados(defaultFormData);
  }, [pageSize, currentPage, selectActive]);

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId || undefined);
  };

  const columns = [
    {
      name: "Título",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Data",
      selector: (row) => dayjs(row.scheduling).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.notificationType,
    },
    {
      name: "Ações",
      width: "60px",
      cell: (row) => (
        <ActionsMenu
          onEdit={() => editChannel(row)}
          onDelete={() => setDeletingContract(row)}
        />
      ),
    },
  ];

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full mb-6">
        <div className="w-1/2">
          <Header
            title="Comunicados"
            subTitle="Visualize, crie e gerencie os comunicados disponibilizados aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Novo comunicado"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/cadastrar-comunicado")}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
        <div className="flex gap-6 w-full">
          <div className="w-1/2">
            <Input
              label="Título"
              placeholder="Digite o título"
              type="text"
              register={register("title")}
            />
          </div>
          <div className="w-1/2">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  label="Disponibilizado para"
                  data={dataType}
                  onChange={(values) => {
                    field.onChange(values);
                    handleMultiSelectChange(values);
                  }}
                  style={{ width: "100%" }}
                  register={field}
                  errors={errors.type}
                  message="Campo obrigatório"
                  file={false}
                  keyField="value"
                  labelField="label"
                />
              )}
            />
          </div>
        </div>
        <div className="flex gap-6 w-full">
          <div className="w-1/2">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  name="startDate"
                  label="Período de publicação"
                  placeholder="Data inicial"
                  type="date"
                />
              )}
            />
          </div>
          <div className="w-1/2">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  name="endDate"
                  label="&nbsp;"
                  placeholder="Data final"
                  type="date"
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={handleReset}
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6 mt-6">
        <div className="flex justify-between items-center">
          <div className="text-[#6D7882]">
            <p>
              <span className="font-bold">{listSize}</span> itens cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        <div className="flex flex-col shadow-custom rounded-lg">
          {loading ? (
            <div className="flex justify-center items-center p-6">
              <Spinner />
            </div>
          ) : (
            <Table
              data={statementData}
              columns={columns} 
            />
          )}
        </div>
      </div>
      {deletingContract && (
        <DeleteModal
          itemName={deletingContract.title}
          confirm={() => deleteItem(deletingContract)}
          cancel={() => setDeletingContract(null)}
        />
      )}
    </Grid2>
  );
}
