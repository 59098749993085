import React from 'react'
import { EditorContainer, StyledLabel } from './styles'

type FeedbackEditorProps = {
  label?: string
  value: string
  onChange: (text: string) => void
  disabled?: boolean
}

export const TextEditor: React.FC<FeedbackEditorProps> = ({
  label,
  value,
  onChange,
  disabled
}) => {
  return (
    <EditorContainer disabled={Boolean(disabled)}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={Boolean(disabled)}
        style={{
          width: '100%',
          height: '200px',
          padding: '10px',
          fontSize: '16px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          resize: 'none',
          background: 'white'
        }}
      />
    </EditorContainer>
  )
}

export default TextEditor
