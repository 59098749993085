// 
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Grid2 } from "@mui/material";
import Header from "../../components/Header";
import SelectEnterprise from "../../components/SelectEnterprise";
import Input from "../../components/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../components/MultiSelect";
import PageSize from "../../components/Table/PageSize";
import Table from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./columns";
import Button from "../../components/Button";
import DeleteModal from "../../components/DeleteModal";
import { IFiles } from "./types";
import file from "../../api/file/file";
import { Spinner } from "../../components/Spinner";

interface Canal {
  id: number;
  Title: string;
  Active: boolean;
  createdAt: string;
  ChannelIcon: string;
}

interface StatusOption {
  value: 'ativo' | 'inativo';
  label: string;
}

export default function FilesPage() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingFile, setDeletingFile] = useState<IFiles | null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [useDataFiles, setUseDataFiles] = useState<any>([]);
  const [selectActive, setSelectActive] = useState();
  const [loading, setLoading] = useState(false);
  const [togglingId, setTogglingId] = useState<string | null>(null);

  const [filterTitle, setFilterTitle] = useState("");
  const [filterStatus, setFilterStatus] = useState<string[]>([]);

  const data = [
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
  ];

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    if (selectedHotelId !== null) {
      setSelectActive(selectedHotelId);
      setCurrentPage(1);
    } else {
      console.error("ID da unidade de rede não definido.");
    }
  };

  const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
    setSelectedValues(values);
  };

  const newChannelCreateSchema = z.object({
    title: z.any().optional(),
    status: z.any().optional()
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
  });

  async function handleFilter(data: any) {
    const title = data.title?.toLowerCase() || "";

    setFilterTitle(title);

    const statusValues = data.status?.map((s: any) => s.value) || [];
    setFilterStatus(statusValues);

    listFiles(pageSize, 1, title, statusValues);
    setCurrentPage(1);
  }

  function resetAll() {
    setPageSize(10);
    setCurrentPage(1);
    setSelectedValues([]);
    reset();
    setFilterTitle("");
    setFilterStatus([]);
    listFiles(10, 1, "", []);
  }

  const editFile = (dataEdit) => {
    navigate("/arquivos/editar-arquivo", {
      state: { isEdit: true, data: dataEdit },
    });
  };

  async function downloadDocumentHandler(userParam: any) {

    if (!userParam.File || typeof userParam.File !== "string") {
      console.error("URL do arquivo inválida ou não definida:", userParam.File);
      return;
    }
    try {
      window.open(userParam.File, '_blank');
    } catch (error) {
      console.error("Erro ao tentar abrir o link de download:", error);
    }
  }


  useEffect(() => {
    const savedNetwork = localStorage.getItem("selectedNetwork");
    if (savedNetwork) {
      setSelectActive(Number(savedNetwork));
    }
  }, []);

  useEffect(() => {
    if (!selectActive) {
      console.warn("selectActive não definido. Não será possível listar arquivos.");
      return;
    }
    listFiles(pageSize, currentPage, filterTitle, filterStatus);
  }, [currentPage, pageSize, selectActive]);

  async function listFiles(
    pageSize: number,
    currentPage: number,
    title: string = "",
    statusValues: string[] = []
  ) {
    if (selectActive === null || selectActive === undefined) {
      console.warn("O selectActive está indefinido ou nulo. Abortando listagem.");
      return;
    }

    setLoading(true);
    try {
      const offSet = (currentPage - 1) * pageSize + 1;
      const response = await file.listFileAdmin(selectActive, pageSize, offSet, title);

      let filteredData = response || [];

      if (statusValues.includes("inativos")) {
        filteredData = filteredData.filter((canal: any) => !canal.Active);
      }
      if (statusValues.includes("ativos")) {
        filteredData = filteredData.filter((canal: any) => canal.Active);
      }

      if (statusValues.length === 1) {

      } else if (statusValues.length === 0 || statusValues.length > 1) {

      }

      setUseDataFiles(filteredData);
    } catch (error) {
      console.error("Erro ao listar os arquivos", error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteItem(deletingItem: any) {
    const response = await file.deleteFile(deletingItem.Id);
    setDeletingFile(null);
    listFiles(pageSize, currentPage, filterTitle, filterStatus);
    return response;
  }

  async function toggleStatus(row: { Active: boolean; Id: string }) {
    const updatedActive = !row.Active;
    try {
        
        setTogglingId(row.Id);
        setUseDataFiles((prevFiles: any[]) =>
            prevFiles.map(file =>
                file.Id === row.Id ? { ...file, Active: updatedActive } : file
            )
        );
        await file.updateFileStatus(row.Id, updatedActive);
    } catch (err) {
        console.error("Erro ao alterar status do arquivo", err);
    } finally {
        setTogglingId(null); 
    }
}


  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Documentos"
            subTitle="Visualize e gerencie os documentos disponibilizados aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Novo documento"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/arquivos/cadastrar-arquivo")}
            />
          </div>
        </div>
      </div>

      <div className="w-screen shadow-custom rounded-lg p-6">
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(handleFilter)}
        >
          <div className="flex gap-6">
            <div className="w-2/4">
              <Input
                label="Nome"
                placeholder="Digite o nome do documento"
                type="text"
                register={register("title")}
              />
            </div>
            <div className="w-2/4">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    label="Status"
                    data={data}
                    onChange={(values) => field.onChange(values)}
                    value={field.value}
                    style={{ width: "100%" }}
                    file={false}
                    keyField="value"
                    labelField="label"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              title="Limpar"
              color="#F28E00"
              backGroundColor="#FCFCFA"
              borderColor="#E3E7FF"
              borderRadius={8}
              onClick={resetAll}
              type="reset"
            />
            <Button
              title="Buscar"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={handleSubmit(handleFilter)}
            />
          </div>
        </form>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882] font-main">
            <p>
              <span className="font-bold font-main">{useDataFiles?.length}</span> itens cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
                listFiles(size, 1, filterTitle, filterStatus);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        <div className="flex justify-center items-center min-h-[200px]">
          {loading ? (
            <Spinner />
          ) : (
            <Table
              data={useDataFiles}
              columns={getColumns(
                editFile,
                setDeletingFile,
                downloadDocumentHandler,
                false,
                toggleStatus,
                togglingId
              )}
              hidePagination
            />
          )}
        </div>
      </div>
      {deletingFile && (
        <DeleteModal
          itemName={deletingFile.name}
          confirm={() => deleteItem(deletingFile)}
          cancel={() => setDeletingFile(null)}
        />
      )}
    </Grid2>
  );
}
