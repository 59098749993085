/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { FormControlLabel, FormGroup, Grid2 } from "@mui/material";
import Header from "../../../components/Header";
import SelectEnterprise from "../../../components/SelectEnterprise";
import Input from "../../../components/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../../components/MultiSelect";
import PageSize from "../../../components/Table/PageSize";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import React, { SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./colums";
import Button from "../../../components/Button";
import DeleteModal from "../../../components/DeleteModal";
import { IFiles } from "../types";
import file from "../../../api/file/file";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { ContinerSwitch } from "../../Channels/Channels/styles";
import apiCategory from "../../../api/category/apiCategory";
import CustomToast from '../../../components/Toast'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from "../../../components/Spinner";

interface Category {
  id: number;
  name: string;
  active: boolean;
}

export default function CategoriesPage() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingFile, setDeletingFile] = useState<IFiles | null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [useDataFiles, setUseDataFiles] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeEdit, setActiveEdit] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectActive, setSelectActive] = useState();
  const [idCategory, setIdCategory] = useState();
  const [loading, setLoading] = useState(false);

  // Estado para armazenar se o filtro de ativo está ligado (true/false) ou indefinido (sem filtro)
  const [filterActive, setFilterActive] = useState<boolean | undefined>(undefined);
  // Estado para armazenar o nome (título) digitado no campo de busca
  const [filterName, setFilterName] = useState<string>("");

  const [togglingId, setTogglingId] = useState<string | null>(null);
  
  const schema = z.object({
    nameCategory: z.string().min(1, "Nome é obrigatório").optional(),  
    isActive: z.boolean().optional(),  
    title: z.string().optional()
  });

  const notify = () => toast("Wow so easy!");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({ resolver: zodResolver(schema) });

  const data = [
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
  ];

  const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
    setSelectedValues(values);
  };

  async function handleFilter(formData: any) {
    setLoading(true);
    try {
      // Se estiver editando/cadastrando categoria:
      if (activeEdit && formData.nameCategory) {
        // Lógica de editar categoria
        // ...
        // Exemplo: apiCategory.editCategory(...)
        // Ao final, você pode fechar o modal, resetar, etc.
        return;
      } 
      else if (!activeEdit && formData.nameCategory) {
        try {
            await apiCategory.postNewCategory(formData.nameCategory);
            toast.success("Categoria cadastrada com sucesso!");
            setOpenModal(false);
            reset();
            listCategories(pageSize, currentPage);
        } catch (error) {
            toast.error("Erro ao cadastrar a categoria.");
            console.error("Erro ao cadastrar categoria", error);
        }
    }
    

      // Caso contrário, é apenas a busca/filtro de listagem:
      const { title } = formData;
      // Mapeando o status selecionado
      const status = selectedValues.map((item: any) => item.value); // ['ativos', 'inativos'], etc.

      // Se o usuário selecionar APENAS "ativos", passamos filterActive = true
      // Se o usuário selecionar APENAS "inativos", passamos filterActive = false
      // Se selecionar ambos ou nenhum, definimos filterActive como undefined
      if (status.length === 1) {
        if (status[0] === "ativos") {
          setFilterActive(true);
        } else if (status[0] === "inativos") {
          setFilterActive(false);
        }
      } else {
        setFilterActive(undefined);
      }

      // Guardamos também o título no estado (para filtrar por nome)
      setFilterName(title?.trim() || "");
      
      // IMPORTANTE: não chamamos listCategories() aqui.
      // Deixamos o useEffect reagir às mudanças de filterActive ou filterName
      // e chamar listCategories() de forma consistente.
    } catch (error) {
      console.error("Erro ao filtrar categorias", error);
    } finally {
      setLoading(false);
    }
  }

  function resetAll() {
    setPageSize(10);
    setCurrentPage(1);
    setSelectedValues([]);
    // Limpamos o estado de filtro
    setFilterActive(undefined);
    setFilterName("");
    reset();
    // Chamamos listCategories explicitamente ou deixamos o useEffect reagir.
    // Aqui vamos forçar, pois é o botão "Limpar"
    listCategories(10, 1); 
  }

  const editFile = (e:any) => {
    setOpenModal(true);
    setActiveEdit(true);
    setIdCategory(e.Id);
    reset({
      nameCategory: e.Name,  
      isActive: e.Active,    
    });
  };

  const toggleStatus = async (row: { Id: string; Active: boolean; Name: string }) => {
    const newStatus = !row.Active;
    try {
      // Mostrar loading no toggle
      setTogglingId(row.Id);

      setUseDataFiles((prev: any[]) =>
        prev.map(item => 
          item.Id === row.Id ? { ...item, Active: newStatus } : item
        )
      );

      await apiCategory.editCategory(row.Name, newStatus, row.Id);
      toast.success("Status alterado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar status:", error);
    } finally {
      setTogglingId(null);
    }
  };

  async function listCategories(pageSize: number, currentPage: number) {
    if (!selectActive) return;
    setLoading(true);
    try {
      // Busca da API, filtrando pela flag ativa/inativa se existir
      const response = await apiCategory.getListCategory(filterActive);

      // Se tivermos algo digitado no título, filtramos localmente
      let filteredData = response;
      if (filterName) {
        filteredData = filteredData.filter((item: { Name: string }) =>
          item.Name?.toLowerCase().includes(filterName.toLowerCase())
        );
      }

      setUseDataFiles(filteredData);
    } catch (error) {
      console.error("Erro ao listar as categorias", error);
    } finally {
      setLoading(false);
    }
  }
  
  async function deleteItem(deletingItem: any) {
    try {
      await apiCategory.deleteCategory(deletingItem.Id);
      listCategories(pageSize, currentPage);
    } catch (error) {
      console.error('Erro ao deletar categoria', error);
    }
  }

  // Ao alterar pageSize, currentPage, filterActive ou filterName,
  // chamamos novamente a listagem para manter o filtro ativo.
  useEffect(() => {
    listCategories(pageSize, currentPage);
  }, [pageSize, currentPage, filterActive, filterName]);
  

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    // Exemplo: se for nulo, apaga; se for numérico, salva
    if (!selectedHotelId) {
      localStorage.removeItem("selectedNetwork");
    } else {
      localStorage.setItem("selectedNetwork", String(selectedHotelId));
    }
    setSelectActive(selectedHotelId);
  };
  

  // Sempre que "selectActive" mudar, chamamos listCategories também
  // (apenas se não quiser depender do useEffect acima)
  useEffect(() => {
    if (selectActive) {
      listCategories(pageSize, currentPage);
    }
  }, [selectActive]);

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Categorias"
            subTitle="Visualize e gerencie as categorias dos documentos disponibilizados."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>
  
          <div>
            <Button
              title="+ Nova categoria"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => {
                setOpenModal(true);
                setActiveEdit(false);
                reset();
              }} 
            />
          </div>
        </div>
      </div>
  
      {openModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={() => { setOpenModal(false); setActiveEdit(false); }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center">
              <div>
                <p className="text-3xl font-bold text-[#404040]">
                  {activeEdit ? 'Editar categoria' : 'Cadastrar categoria'}
                </p>
              </div>
              <div>
                <IoIosCloseCircleOutline 
                  size={30} 
                  style={{ cursor: 'pointer' }} 
                  onClick={() => { setOpenModal(false); setActiveEdit(false); }} 
                />
              </div>
            </div>
            <form className="pt-7">
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Input 
                    label="Nome"
                    type="text"
                    placeholder=""
                    register={register("nameCategory")}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", gap: "16px", paddingTop: '24px' }}>
                <button
                  type="button"
                  onClick={() => { setOpenModal(false); setActiveEdit(false); }}
                  style={{
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    color: "#8A97B1",
                    outline: "none",
                  }}
                >
                  Fechar
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(handleFilter)}
                  style={{
                    backgroundColor: "#F28E00",
                    color: "#fff",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {activeEdit ? 'Salvar' : 'Adicionar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
  
      <div className="w-screen shadow-custom rounded-lg p-6">
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleFilter)}>
          <div className="flex gap-6">
            <div className="w-2/4">
              <Input
                label="Nome"
                placeholder="Digite o nome da categoria"
                type="text"
                register={register("title")}
              />
            </div>
            <div className="w-2/4">
              <MultiSelect
                label="Status"
                data={data}
                onChange={handleMultiSelectChange}
                style={{ width: "100%" }}
                keyField="value"
                labelField="label"
              />
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              title="Limpar"
              color="#F28E00"
              backGroundColor="#FCFCFA"
              borderColor="#E3E7FF"
              borderRadius={8}
              onClick={resetAll}
              type="reset"
            />
            <Button
              title="Buscar"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={handleSubmit(handleFilter)}
            />
          </div>
        </form>
      </div>
  
      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882] font-main">
            <p>
              <span className="font-bold font-main">{useDataFiles?.length}</span> categorias cadastradas
            </p>
          </div>
          <PageSize 
            pageSize={pageSize} 
            setPageSize={(size) => { 
              setPageSize(size); 
              setCurrentPage(1); 
            }} 
          />
        </div>
  
        {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <Table
            data={useDataFiles}
            columns={getColumns(editFile, setDeletingFile, false, toggleStatus, togglingId)}
            hidePagination
          />
        )}
      </div>
  
      {deletingFile && (
        <DeleteModal
          itemName={deletingFile.Name}
          confirm={() => deleteItem(deletingFile)}
          cancel={() => setDeletingFile(null)}
        />
      )}
    </Grid2>
  );
}