import ky from "ky";
import { IListUsers } from "./types";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL;

class FileApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewFile(
    Icon: File | null,
    Files: File[] | File,
    Name: string,
    Type: string,
    CategoryId: string
  ): Promise<unknown> {
    const formData = new FormData();
    const selectedNetwork = localStorage.getItem("selectedNetwork");
    if (selectedNetwork) {
        formData.append("NetworkUnitId", selectedNetwork.toString());
    } else {
        console.error("NetworkUnitId não encontrado");
    }

    formData.append("Name", Name);
    formData.append("Type", Type);
    formData.append("CategoryId", CategoryId);

    if (Icon) {
        formData.append("Icon", Icon);
    }

    const fileArray = Array.isArray(Files) ? Files : [Files];
    fileArray.forEach((file) => formData.append("File", file));

    try {
        const headers = await this.getHeaders();
        delete headers["Content-Type"]; 
        const data = await ky.post(`${API_URL}file`, {
            body: formData,
            headers,
        });
        return data;
    } catch (error) {
        console.error("Erro ao cadastrar novo arquivo", error);
        throw error;
    }
}

  public async listFileAdmin(
    networkUnitId: number, 
    pageSize: number, 
    offSet: number, 
    fileName: string = '', 
  ): Promise<unknown> {
    const headers = await this.getHeaders("application/json");

    const body = {
      networkUnitId: networkUnitId,
      pageSize: pageSize,
      offSet: offSet,
      fileName: fileName
    };

    try {
      const response = await ky.post(`${API_URL}file/admin/list-file`, {
        headers,
        json: body
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao listar arquivos (admin)", error);
      throw error;
    }
  }  

  public async listUsers(): Promise<IListUsers[] | undefined> {
    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork") || '0';

    try {
      const response = await ky.get(
        `${API_URL}user/investor/network-unit/${selectedNetwork}`,
        { headers }
      );
      const data: IListUsers[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao listar usuários", error);
    }
  }

  public async updateFileStatus(fileId: number, active: boolean): Promise<void> {
    const headers = await this.getHeaders("application/json");
    
    try {
        await ky.put(`${API_URL}file/update/${fileId}/${active}`, {
            headers,
        });
    } catch (error) {
        console.error("Erro ao atualizar o status do arquivo", error);
        throw error;
    }
}

  public async deleteFile(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `${API_URL}file/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar comunicado", error);
    }
  }

  public async editFile(
    fileId: number,
    Icon: File | null,
    Name: string,
    CategoryId: number,
    File: File[]
): Promise<void> {
    const formData = new FormData();
    formData.append("Name", Name);
    formData.append("CategoryId", CategoryId.toString());
    if (Icon) {
        formData.append("Icon", Icon);
    }
    File.forEach((file) => {
        formData.append("File", file);
    });

    try {
        const headers = await this.getHeaders();
        await ky.put(`${API_URL}file/${fileId}`, {
            body: formData,
            headers,
        });
    } catch (error) {
        console.error("Erro ao editar arquivo", error);
        throw error;
    }
  }
}

export default new FileApi();
