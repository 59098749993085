import ky from "ky";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class CategoryAPI {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewCategory(title: string): Promise<void> {
    const networkUnitId = Number(localStorage.getItem("selectedNetwork"));
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const dataBody = { titulo: title, networkUnitId };
    try {
      const headers = await this.getHeaders();
      const response = await ky.post(`${API_URL}category`, {
        json: dataBody,
        headers,
      });

      if (![200, 201, 204].includes(response.status)) {
        throw new Error("Erro ao criar a categoria.");
      }
    } catch (error) {
      console.error("Erro ao cadastrar nova categoria", error);
      throw error;
    }
  }

  public async getListCategory(active?: boolean): Promise<any[]> {
    const networkUnitId = Number(localStorage.getItem("selectedNetwork"));
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    try {
      const headers = await this.getHeaders();
      const url = new URL(`${API_URL}category/${networkUnitId}`);

      if (typeof active === "boolean") {
        url.searchParams.set("active", active.toString());
      }

      const response = await ky.get(url.toString(), { headers });
      return await response.json();
    } catch (error) {
      console.error("Erro ao listar categorias", error);
      throw error;
    }
  }

  public async editCategory(title: string, active: boolean, categoryId: string): Promise<void> {
    const networkUnitId = Number(localStorage.getItem("selectedNetwork"));
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const dataBody = { title, active };
    try {
      const headers = await this.getHeaders();
      const response = await ky.put(`${API_URL}category/${categoryId}`, {
        json: dataBody,
        headers,
      });

      if (![200, 204].includes(response.status)) {
        throw new Error("Erro ao editar a categoria.");
      }
    } catch (error) {
      console.error("Erro ao editar categoria", error);
      throw error;
    }
  }

  public async deleteCategory(id: string): Promise<void> {
    const networkUnitId = Number(localStorage.getItem("selectedNetwork"));
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    try {
      const headers = await this.getHeaders();
      const response = await ky.delete(`${API_URL}category/${id}`, { headers });
      if (![200, 204].includes(response.status)) {
        throw new Error("Erro ao deletar a categoria.");
      }
    } catch (error) {
      console.error("Erro ao deletar categoria", error);
      throw error;
    }
  }
}

export default new CategoryAPI();