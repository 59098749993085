import React, { useState, useEffect } from 'react';
import { Grid2 } from '@mui/material';
import Header from "../../components/Header";
import Button from "../../components/Button";
import SelectEnterprise from "../../components/SelectEnterprise";
import Input from "../../components/Input";
import Table from "../../components/Table";
import { getColumns } from './columns';
import InvestorApi from '../../api/investors/investors'; 
import PageSize from '../../components/Table/PageSize';
import Pagination from '../../components/Table/Pagination';
import { Spinner } from '../../components/Spinner';
import MultiSelect from '../../components/MultiSelect';

interface Proprietario {
  Id: number;
  Name: string;
  Email: string;
  Address: string;
  DocumentType: number;
  Document: string;
  uh: string[]; 
}

export default function Owners() {
  const [selectActive, setSelectActive] = useState<number | null>(null);

  const [nomeFiltro, setNomeFiltro] = useState<string>("");
  const [emailFiltro, setEmailFiltro] = useState<string>("");
  const [uhSelected, setUhSelected] = useState<any[]>([]);
  const [uhOptions, setUhOptions] = useState<any[]>([]);
  const [loadingUHs, setLoadingUHs] = useState<boolean>(false);

  const [allProprietarios, setAllProprietarios] = useState<Proprietario[]>([]);
  const [proprietarios, setProprietarios] = useState<Proprietario[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    if (selectActive !== selectedHotelId) {
      setSelectActive(selectedHotelId);
      setCurrentPage(1);
      setUhOptions([]);
      setUhSelected([]);
    }
  };


  const fetchUHs = async (networkUnitId: number | null) => {
    if (!networkUnitId) return;
    setLoadingUHs(true);
    try {
      const response = await InvestorApi.listInvestor(
        networkUnitId, 
        999999, 
        1,
        "",
        "",
        ""
      );

      if (response?.data?.investors) {
        const uhs = response.data.investors
          .flatMap((investor) => investor.uh || [])
          .map((uhStr) => {
            try {
              return JSON.parse(uhStr);
            } catch (e) {
              console.warn("Não foi possível parsear UH:", uhStr);
              return null;
            }
          })
          .filter(Boolean) 
          .map((uh) => ({ Id: uh.id, Name: (uh.UHName || "").trim() }));

        const uniqueUhs = Array.from(
          new Map(uhs.map((uh) => [uh.Id, uh])).values()
        );
        setUhOptions(uniqueUhs);
      }
    } catch (error) {
      console.error("Erro ao buscar UHs", error);
    } finally {
      setLoadingUHs(false);
    }
  };


  const fetchAllProprietarios = async () => {
    if (!selectActive) return;
    setLoading(true);

    try {
    
      const response = await InvestorApi.listInvestor(
        selectActive, 
        999999, 
        1
      );

      const data = response?.data?.investors || [];

      setAllProprietarios(data);
    } catch (error) {
      console.error("Erro ao listar proprietários", error);
      setAllProprietarios([]);
    } finally {
      setLoading(false);
    }
  };

  
  const applyFiltersAndPaginate = () => {

    let filtered = allProprietarios.filter((prop) => {
      const nameMatch = prop.Name
        .toLowerCase()
        .includes(nomeFiltro.toLowerCase());
      const emailMatch = prop.Email
        .toLowerCase()
        .includes(emailFiltro.toLowerCase());

      if (uhSelected.length > 0) {
      
        const selectedUHIds = uhSelected.map((uh) => uh.Id);

        let hasMatchingUH = false;
        for (const uhString of prop.uh) {
          try {
            const parsedUH = JSON.parse(uhString);
            if (selectedUHIds.includes(parsedUH.id)) {
              hasMatchingUH = true;
              break;
            }
          } catch (e) {
            
            console.warn("UH inválida para o proprietário:", uhString);
          }
        }

        return nameMatch && emailMatch && hasMatchingUH;
      }

      return nameMatch && emailMatch;
    });

    const offset = (currentPage - 1) * pageSize;
    const paginatedData = filtered.slice(offset, offset + pageSize);

    setProprietarios(paginatedData);
    setTotalItems(filtered.length);
  };

  const handleSearch = () => {
    setCurrentPage(1); 
    applyFiltersAndPaginate();
  };

  const handleClear = () => {
    setNomeFiltro("");
    setEmailFiltro("");
    setUhSelected([]);
    setCurrentPage(1);
  };


  useEffect(() => {
    applyFiltersAndPaginate();
  }, [allProprietarios, currentPage, pageSize, uhSelected, nomeFiltro, emailFiltro]);


  useEffect(() => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (networkUnitId) {
      setSelectActive(Number(networkUnitId));
    }
  }, []);

  
  useEffect(() => {
    if (selectActive !== null) {
      fetchUHs(selectActive);
      fetchAllProprietarios(); 
    }
  }, [selectActive]);

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Proprietários"
            subTitle="Visualize e gerencie os proprietários investidores."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6">
          <div className="w-2/4">
            <Input
              label="Nome"
              placeholder="Digite o nome do proprietário"
              type="text"
              value={nomeFiltro}
              onChange={(e) => setNomeFiltro(e.target.value)}
            />
          </div>
          <div className="w-2/4">
            <Input
              label="E-mail"
              placeholder="Digite o e-mail do proprietário"
              type="text"
              value={emailFiltro}
              onChange={(e) => setEmailFiltro(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-2/4">
            <MultiSelect
              label="Unidade Habitacional (UH)"
              data={uhOptions}
              onChange={setUhSelected}
              keyField="Id"
              labelField="Name"
              style={{}} 
              register={undefined}
              errors={undefined}
              message={""} 
              file={null} 
            />
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={handleClear} 
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            borderColor=""
            onClick={handleSearch}
          />
        </div>
      </div>

      {/* Tabela + Paginação */}
      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        {/* Cabeçalho da paginação */}
        <div className="flex justify-between">
          <div className="text-[#6D7882] font-main">
            <p>
              <span className="font-bold font-main">{totalItems}</span> itens cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size: number) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        {loading ? (
          <div className="text-center font-bold text-lg py-5">
            <Spinner />
          </div>
        ) : (
          <Table data={proprietarios} columns={getColumns()} />
        )}

        <Pagination
          pageCount={Math.ceil(totalItems / pageSize)}
          currentPage={currentPage}
          setPage={setCurrentPage}
        />
      </div>
    </Grid2>
  );
}
