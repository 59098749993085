import ky from "ky";
import { PresignedUrlResponse } from "./type";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class S3Api {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  
  public async UpdatePresignedUrl(filePath: string, file: File): Promise<string> {
    const networkUnitId = localStorage.getItem("selectedNetwork");
  
    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
  
    try {
      const headers = await this.getHeaders();
  
      const response = await ky.get(
        `${API_URL}s3/${filePath}`,
        { headers }
      );

      const presignedUrl = await response.json() as string;
      

       await ky.put(presignedUrl, {
        body: file, 
      });

      const s3Url = `${presignedUrl.split('?')[0]}`;

  
      return s3Url;
    } catch (error) {
      console.error("Erro ao cadastrar novo benefício", error);
      throw error;
    }
  }
}

export default new S3Api();
