import { TextAreaInputProps } from "./types";

export default function TextArea({ label, value, onChange, register, errors }: TextAreaInputProps) {
  return (
    <div className="flex flex-col gap-2 font-main w-full">
      <label htmlFor="" className="text-[#404040] font-medium text-base">
        {label}
      </label>
      <div className="relative w-full">
        <textarea
          className={`rounded p-2 border bg-white border-[#E3E7FF] outline-none placeholder:text-[#8A97B1] w-full h-[155px] `}
          value={value}
          onChange={onChange}
          {...register}
        />
      </div>
      {errors && <p className="text-red-500 text-sm">{errors.message}</p>}
    </div>
  );
}
