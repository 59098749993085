import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ActionsMenu from "../../../components/Table/ActionsMenu";
import { IFiles } from "../types";
import { ContinerSwitch } from "../../Channels/Channels/styles";
import React from "react";
import { Spinner } from "../../../components/Spinner";

interface IRow {
  Name: string;
  Active: boolean;
  Id: string;
}

export function getColumns(
  toggleIsEdit?: ((row: IFiles) => void) | null,
  deleteHotelChain?: ((row: IFiles) => void) | null,
  exist?: boolean,
  toggleStatus?: ((row: { Id: string; Active: boolean; Name: string }) => void) | null,
  togglingId?: string | null
) {
  const columns = [
    {
      name: "Nome",
      selector: (row: IRow) => <p>{row.Name}</p>,
    },
    {
      name: "Status",
      selector: (row: IRow) => {
        if (togglingId === row.Id) {
          return (
            <ContinerSwitch>
              <Spinner />
            </ContinerSwitch>
          );
        }

        const handleChange = async () => {
          try {
            if (row && toggleStatus) {
              await toggleStatus({ Id: row.Id, Active: row.Active, Name: row.Name });
            }
          } catch (error) {
            console.error("Erro ao alternar o status:", error);
          }
        };

        return (
          <ContinerSwitch>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={row.Active}
                    edge="start"
                    onChange={handleChange}
                  />
                }
                label={row.Active ? "Ativo" : "Inativo"}
              />
            </FormGroup>
          </ContinerSwitch>
        );
      },
    },
    {
      width: "60px",
      cell: (row: IFiles) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];

  return columns;
}
