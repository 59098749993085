import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconLogo from "../../assets/icons/icon-jira.png";
import Button from "../Button";
import Input from "../Input";
import LoginAPI from "../../api/login/login";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import UserApi from "../../api/user/user";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const newLoginSchema = z.object({
    email: z.string().min(1, "E-mail é obrigatório"),
    password: z.string().min(1, "Senha é obrigatório"),
  });

  type NewLoginSchema = z.infer<typeof newLoginSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewLoginSchema>({
    resolver: zodResolver(newLoginSchema),
  });

  async function handleSubmitForm(data: NewLoginSchema) {
    if (loading) return; // Impede múltiplos envios enquanto "Carregando..."

    setLoading(true);
    setErrorMessage("");

    try {
      const response = await LoginAPI.postLogin(data.email, data.password);

      if (response?.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("userId", response.userId);

        const network = await UserApi.listUserNetworks(parseInt(response.userId));
        if (network) {
          localStorage.setItem("networks", JSON.stringify(network));
          localStorage.setItem("selectedNetwork", JSON.stringify(network[0].Id));
        }

        navigate("/");
      } else {
        setErrorMessage("E-mail ou senha incorretos.");
      }
    } catch (error) {
      console.error("Erro ao realizar login", error);
      setErrorMessage("Erro ao realizar login. Tente novamente.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col gap-6 justify-center items-center bg-red-[#F4F5F7] h-[100vh]">
      <div className="flex flex-col gap-6 bg-white p-16 shadow-md rounded-lg">
        <img src={IconLogo} width={260} height={350} alt="" />
        <form
          className="flex flex-col gap-6 w-72"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div className="flex flex-col gap-6">
            <Input
              label="E-mail"
              placeholder="Digite seu e-mail."
              type="text"
              register={register("email")}
              errors={errors.email}
            />
            <Input
              label="Senha"
              placeholder="Digite sua senha."
              type="text"
              password={true}
              register={register("password")}
              errors={errors.password}
            />
            {errorMessage && (
              <div className="text-red-500 text-sm">{errorMessage}</div>
            )}
            <Button
              title={loading ? "Carregando..." : "Entrar"}
              backGroundColor={loading ? "#d3d3d3" : "#F28E00"} 
              color="white"
              borderRadius={8}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
