import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ActionsMenu from "../../components/Table/ActionsMenu";
import { IFiles } from "./types";
import { ContinerSwitch } from "../Channels/Channels/styles";
import { Spinner } from "../../components/Spinner";

interface IRow {
  Icon: string;
  Name: string;
  category: string;  // Confirmando que é o nome da categoria
  Id: string;
  Active: boolean;
}

export function getColumns(
  toggleIsEdit?: ((row: IFiles) => void) | null,
  deleteHotelChain?: ((row: IFiles) => void) | null,
  onDownload?: ((row: IFiles) => void) | null,
  exist?: boolean,
  toggleStatus?: ((row: { Active: boolean; Id: string }) => void) | null,
  togglingId?: string | null 
) {
  const columns = [
    {
      name: "Ícone",
      selector: (row: IRow) => <img src={row.Icon} alt="Ícone" />,
      width: '100px'
    },
    {
      name: "Nome do grupo",
      selector: (row: IRow) => <span>{row.Name}</span>,
    },
    {
      name: "Categoria", 
      selector: (row: IRow) => (
        <span>
          {row.category ? row.category : "Não Informada"}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row: IRow) => {
        const handleChange = async () => {
          if (toggleStatus) {
            await toggleStatus({ Id: row.Id, Active: row.Active });
          }
        };

        return (
          <ContinerSwitch>
            <FormGroup>
              <FormControlLabel
                control={
                  togglingId === row.Id ? (
                    <Spinner />
                  ) : (
                    <Switch
                      checked={row.Active}
                      edge="start"
                      onChange={handleChange}
                    />
                  )
                }
                label={togglingId === row.Id ? "" : row.Active ? "Ativo" : "Inativo"} 
              />
            </FormGroup>
          </ContinerSwitch>
        );
      },
    },
    {
      width: "60px",
      cell: (row: IFiles) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDownload={onDownload ? () => onDownload(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];

  if (exist) {
    columns.splice(2, 0, {
      name: "Status",
      selector: (row: IRow) => (
        <FormGroup>
          {row.Active ? (
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Ativo"
              disabled
            />
          ) : (
            <FormControlLabel
              control={<Switch />}
              label="Inativo"
              disabled
            />
          )}
        </FormGroup>
      ),
    });
  }

  return columns;
}
