import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; 
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import BreadcrumbsPage from "../../../components/Breadcrumbs";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import { ContinerSwitch } from "../../Channels/Channels/styles";
import ChannelAPI from "../../../api/channel/channel"; 
import UserApi from "../../../api/user/user"; 
import { Spinner } from "../../../components/Spinner";

import IconMessage from "../../../assets/icons/message-icon.svg";
import IconCommunity from "../../../assets/icons/fone-icon.svg";
import IconFile from "../../../assets/icons/icon-file.svg";
import IconPoints from "../../../assets/icons/icon-benefits.svg";
import UserTie from "../../../assets/icons/user-tie.svg";
import User from "../../../assets/icons/icon-user.svg";

const newUserSchema = z
  .object({
    name: z.string().min(1, "Nome é obrigatório"),
    email: z.string().email("E-mail inválido"),
    password: z.string().min(6, "A senha deve ter no mínimo 6 caracteres"),
    confirmPassword: z.string().min(6, "Confirme a senha corretamente"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "As senhas não coincidem",
    path: ["confirmPassword"],
  });

type NewUserSchema = z.infer<typeof newUserSchema>;

const modulesData = [
  { 
    title: "Atendimento", 
    id: 1,
    icon: IconMessage, 
    active: true, 
    subModules: [
      { title: "Mensagens", id: 101, active: true }, 
      { title: "Canais", id: 102, active: true }
    ] 
  },
  { title: "Proprietários", id: 2, icon: UserTie, active: true },
  { title: "Comunicados", id: 3, icon: IconCommunity, active: true },
  { 
    title: "Documentos", 
    id: 4, 
    icon: IconFile, 
    active: true, 
    subModules: [
      { title: "Lista de documentos", id: 401, active: true }, 
      { title: "Categorias", id: 402, active: true }
    ] 
  },
  { 
    title: "Programa de fidelidade", 
    id: 5, 
    icon: IconPoints, 
    active: true, 
    subModules: [
      { title: "Bônus", id: 501, active: true }, 
      { title: "Benefícios", id: 502, active: true }
    ] 
  },
  { title: "Gestão de usuários", id: 6, icon: User, active: true },
];

export default function NewUser() {
  const [chatTopics, setChatTopics] = useState<any[]>([]);
  const [modules, setModules] = useState(modulesData);
  const networkUnitId = Number(localStorage.getItem("selectedNetwork") || 0);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  const isEdit = location.state?.isEdit || false;
  const dataEdit = location.state?.data || null; 

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<NewUserSchema>({
    resolver: zodResolver(newUserSchema),
  });
  
  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit.name || "");
      setValue("email", dataEdit.email || "");
      
    }
  }, [isEdit, dataEdit, setValue]);

  useEffect(() => {
    const fetchChatTopics = async () => {
      setLoading(true);
      try {
        const topics = await ChannelAPI.listChatTopics(networkUnitId);
        setChatTopics(topics.map((topic: any) => ({ ...topic, active: topic.Active })));
      } catch (error) {
        console.error("Erro ao carregar canais de atendimento", error);
      } finally {
        setLoading(false);
      }
    };
    fetchChatTopics();
  }, [networkUnitId]);

  const toggleModuleStatus = (index: number) => {
    const updatedModules = [...modules];
    const currentModule = updatedModules[index];

    currentModule.active = !currentModule.active;

    if (!currentModule.active && Array.isArray(currentModule.subModules)) {
      currentModule.subModules = currentModule.subModules.map(subModule => ({
        ...subModule,
        active: false,
      }));
    }
    setModules(updatedModules);
  };

  const toggleSubModuleStatus = (moduleIndex: number, subModuleIndex: number) => {
    const updatedModules = [...modules];
    const currentModule = updatedModules[moduleIndex];
    if (currentModule && currentModule.subModules) {
      const subModule = currentModule.subModules[subModuleIndex];
      if (subModule) {
        subModule.active = !subModule.active;
      }
    }
    setModules(updatedModules);
  };

  const onSubmit = async (data: NewUserSchema) => {
    setLoading(true);
  
    try {
      if (!isEdit) {
        
        const createPayload = {
          name: data.name,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          networkUnitId: networkUnitId,
          chatTopics: chatTopics.map(topic => ({
            chaTopicId: topic.Id,
            active: topic.active,
          })),
          modules: modules.flatMap(module => [
            { modulesId: module.id, active: module.active },
            ...(module.subModules?.map(sub => ({ modulesId: sub.id, active: sub.active })) || []),
          ]),
        };
  
        await UserApi.createUser(createPayload);
  
      } else {
        
        const updatePayload = {
          id: dataEdit?.id || 0,
          name: data.name,
          email: data.email,
          address: dataEdit?.address || "", 
          phone: dataEdit?.phone || "",
          deviceId: dataEdit?.deviceId || "",
          networkUnitId: networkUnitId,
          
          newPassword: data.password,
          newPasswordConfirm: data.confirmPassword,
          chatTopics: chatTopics.map(topic => ({
            chaTopicId: topic.Id,
            active: topic.active,
          })),
          modules: modules.flatMap(module => [
            { modulesId: module.id, active: module.active },
            ...(module.subModules?.map(sub => ({ modulesId: sub.id, active: sub.active })) || []),
          ]),
        };
  
        await UserApi.updateUser(dataEdit.id, updatePayload);
      }
  
      navigate("/gestao-usuarios");
    } catch (error) {
      console.error("Erro ao cadastrar/atualizar usuário:", error);
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div className="flex flex-col gap-2">
        <BreadcrumbsPage />
        <div className="w-1/2">
          <Header
            title={isEdit ? "Editar Usuário" : "Cadastrar Novo Usuário"}
            arrowIcon={true}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
        <h3 className="font-main text-lg font-bold">Dados Gerais</h3>
        <form id="user-form" onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <div className="flex gap-4">
            <div className="w-1/2">
              <Input
                label="Nome"
                type="text"
                placeholder="Digite o nome do usuário"
                register={register("name")}
                errors={errors.name}
              />
            </div>
            <div className="w-1/2">
              <Input
                label="E-mail"
                type="email"
                placeholder="Digite o e-mail do usuário"
                register={register("email")}
                errors={errors.email}
              />
            </div>
          </div>

          <div className="flex gap-4">
            <div className="w-1/2">
              <Input
                label="Senha"
                type="password"
                placeholder="Digite a senha"
                register={register("password")}
                errors={errors.password}
              />
            </div>
            <div className="w-1/2">
              <Input
                label="Confirmar Senha"
                type="password"
                placeholder="Confirme a senha"
                register={register("confirmPassword")}
                errors={errors.confirmPassword}
              />
            </div>
          </div>
        </form>
      </div>

      {/* Seção de Módulos */}
      <div className="flex flex-col gap-4 w-full shadow-custom rounded-lg p-6">
      <h3 className="font-main text-lg font-bold">Módulos</h3>
      {modules.map((module, index) => (
        <Accordion key={index} defaultExpanded={Array.isArray(module.subModules) && module.subModules.length > 0}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-4">
                <div
                  style={{
                    backgroundColor: "#27365B",
                    padding: "8px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <img src={module.icon} alt={module.title} style={{ width: "20px", height: "20px", filter: "brightness(0) invert(1)" }} />
                </div>
                <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                  {module.title}
                </Typography>
              </div>
              <ContinerSwitch>
                <FormControlLabel
                  control={
                    <Switch
                      checked={module.active}
                      onChange={() => toggleModuleStatus(index)}
                      edge="start"
                    />
                  }
                  label={module.active ? "Ativo" : "Inativo"}
                />
              </ContinerSwitch>
            </div>
          </AccordionSummary>

          {Array.isArray(module.subModules) && (
            <AccordionDetails>
              {module.subModules.map((sub, subIndex) => (
                <div key={subIndex} className="flex items-center justify-between py-2 px-4">
                  <span>{sub.title}</span>
                  <ContinerSwitch>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sub.active}
                          onChange={() => toggleSubModuleStatus(index, subIndex)}
                        />
                      }
                      label={sub.active ? "Ativo" : "Inativo"}
                    />
                  </ContinerSwitch>
                </div>
              ))}
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </div>

      {/* Seção de Canais de Atendimento */}
      <div className="flex flex-col gap-4 w-full shadow-custom rounded-lg p-6 mt-6">
        <h3 className="font-main text-lg font-bold">Canais de atendimento</h3>
        {loading ? (
          <div className="text-center">Carregando...</div>
        ) : chatTopics.length > 0 ? (
          chatTopics.map((channel, index) => (
            <div key={index} className="flex justify-between py-2 border-b">
              <span className="font-medium">{channel.Title}</span>
              <ContinerSwitch>
                <FormControlLabel
                  control={<Switch defaultChecked={channel.Active} onChange={(e) => {
                    // atualiza o estado local se quiser
                    const updated = [...chatTopics];
                    updated[index].active = e.target.checked;
                    setChatTopics(updated);
                  }}/>}
                  label={channel.Active ? "Ativo" : "Inativo"}
                />
              </ContinerSwitch>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">Nenhum canal disponível.</div>
        )}
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10">
        <Button
          title="Voltar"
          onClick={() => navigate(-1)}
          backGroundColor={"#FCFCFA"}
          color={"#F28E00"}
          borderRadius={8}
        />
        {loading ? (
          <Spinner /> 
        ) : (
          <Button
            title="Cadastrar Usuário"
            backGroundColor={"#F28E00"}
            color={"#FAFAFC"}
            borderRadius={8}
            type="submit"
            form="user-form"
          />
        )}
      </div>
    </div>
  );
}
