// investors.ts (API)
import ky from 'ky';
import { Payload } from './model';

interface Investor {
  Id: number;
  Name: string;
  Email: string;
  Address: string;
  DocumentType: number;
  Document: string;
  uh: string[];
}

interface ListInvestorResponse {
  data?: {
    investors: Investor[];
    totalItems: number;
  };
}

const API_URL = 'https://investor-v2.api.vegait.com/api/1/';

class InvestorApi {

  private async getHeaders(contentType?: string): Promise<Record<string, string>> {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    } else {
      console.warn("Nenhum token foi encontrado no localStorage.");
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async listInvestor(
    networkUnitId: number,
    pageSize: number,
    offSet: number,
    name?: string,
    email?: string,
    uhs?: any
  ): Promise<ListInvestorResponse | undefined> {
    const selectedNetwork = localStorage.getItem("selectedNetwork");
    if (!selectedNetwork) {
      console.warn("Nenhum networkUnitId foi encontrado no localStorage.");
    }
  
    const networkUnitIdValue = Number(selectedNetwork) || networkUnitId;
  
    const payload: Payload = {
      networkUnitId: networkUnitIdValue,
      pageSize,
      offSet
    };
  
    if (name !== undefined) {
      payload.Name = name; 
    }
  
    if (email !== undefined) {
      payload.Email = email; 
    }
  
    if (uhs) {
      payload.RoomsIds = uhs.map((uh: any) => uh.Id); 
    }
  
    try {
      const headers = await this.getHeaders("application/json");
  
      const response = await ky.post(`${API_URL}investor/admin/list-investor`, {
        headers,
        json: payload
      }).json();
  
      if (!Array.isArray(response)) {
        console.warn("A resposta da API não é um array conforme esperado. Resposta:", response);
        return undefined;
      }
  
      const investors: Investor[] = response;
      const totalItems = investors.length;
  
      return {
        data: {
          investors,
          totalItems
        }
      };
  
    } catch (error) {
      console.error("Erro na requisição listInvestor", error);
      return undefined;
    }
  }
}

export default new InvestorApi();
